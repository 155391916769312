
import Vue from 'vue'
import Component from 'vue-class-component'
import { User } from '@/apis/User'
import { Prop, PropSync } from 'vue-property-decorator'

@Component({
  name: 'DeleteUser'
})

export default class BackUsersModal extends Vue {
  @PropSync('isShowModal', { type: Boolean }) syncShowModal!: boolean
  @Prop(String) requestId: any

  deleteUser() {
    User.deleteUser(this.$router.currentRoute.params.id)
      .then(({ data }) => {
        if (data.success === true) {
          // this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Users' })
        } else {
          // this.showErrorSystemNotification(data.msg)
        }
      }).catch((errors) => {
      // this.showErrorSystemNotification('Ошибка удаления пользователя')
    })
  }
}
