
import Vue from 'vue'
import Component from 'vue-class-component'
import AdminUserForm from '@/views/Admin/Users/components/AdminUserForm.vue'
import { User } from '@/apis/User'
import { UserRoleId } from '@/typings/AdminTyping'
import DeleteUser from '@/views/Admin/Users/components/deleteUser.vue'

@Component({
  name: 'AddUser',
  components: {
    DeleteUser,
    AdminUserForm
  }
})

export default class AddUser extends Vue {
  roles = []
  brands = []
  leasingCompanies = []
  dealerships = []
  statuses = []
  userDetails = {}
  isNotNewUser = true
  tgData = null
  deleteUserModal = false

  created() {
    this.fetchDetailsForUpdateUser(
      this.$router.currentRoute.params.id
    )
  }

  fetchDetailsForUpdateUser($id) {
    User.fetchDetailsForUpdateUser($id)
      .then(({ data }) => {
        console.log(data)
        this.roles = data.roles
        this.brands = data.brands
        this.dealerships = data.dealerships
        this.leasingCompanies = data.leasingCompanies
        this.statuses = data.statuses
        this.userDetails = data.userDetails
        this.tgData = data.tgData
        this.getCompanyIds()
        this.getBrandIds()
      })
  }

  updateUser() {
    User.updateUser(this.userDetails, this.$router.currentRoute.params.id)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Users' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Заполните поля для сохранения')
      })
  }

  getCompanyIds() {
    // @ts-ignore
    if (this.userDetails.user_role_id !== UserRoleId.Admin) {
      // @ts-ignore
      if (this.userDetails.user_role_id === UserRoleId.ManagerDealership) {
        const companyIds = []
        // @ts-ignore
        for (const item of this.userDetails.dealership) {
          // @ts-ignore
          companyIds.push(item.id)
        }
        // @ts-ignore
        this.userDetails.companyIds = companyIds
      } else {
        // @ts-ignore
        this.userDetails.companyIds = this.userDetails.leasing_company[0].id
      }
    }
  }

  getBrandIds() {
    // @ts-ignore
    if (this.userDetails.user_role_id === UserRoleId.ManagerDealership) {
      const brandIds = []
      // @ts-ignore
      for (const item of this.userDetails.brands) {
        // @ts-ignore
        brandIds.push(item.id)
      }
      // @ts-ignore
      this.userDetails.brandIds = brandIds
    }
  }
}
